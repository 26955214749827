<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <h2 class="mb-4">
          Chart Display
        </h2>

        <v-text-field
          v-model="form.nicename"
          label="Chart Title"
          outlined
          :rules="[required]"
        ></v-text-field>

        <v-select
          v-model="form.cols"
          label="Display Col span"
          :items="colsOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-text-field
          v-model="form.sort"
          label="Sort (asc)"
          outlined
        ></v-text-field>

        <v-select
          v-model="form.formatter"
          :label="`Value Format (No. / $ ...)`"
          :items="formatterOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-divider class="mb-4" />

        <h2 class="mb-4">
          Resource Control and Filter
        </h2>

        <v-select
          v-model="form.route"
          label="Resource route"
          :items="routeOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-select
          v-model="form.query.action"
          label="Chart Action (Count or sum total)"
          :items="actionOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-select
          v-if="form.query.action === 'sum'"
          v-model="form.query.action_value"
          label="Summarise by field"
          :items="actionValueOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-select
          v-model="form.filter.nature"
          :label="`Nature`"
          :items="natureOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
        ></v-select>

        <v-select
          v-model="form.filter.merchant_id"
          :label="`Merchant`"
          :items="merchantOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
        ></v-select>

        <v-select
          v-model="form.filter.channel_id"
          :label="`Channel`"
          :items="channelOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
        ></v-select>

        <v-divider class="mb-4" />

        <h2 class="mb-4">
          Record Interval Range
        </h2>

        <v-select
          v-model="form.query.interval_unit"
          :label="`Record Unit`"
          :items="unitOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-select
          v-model="form.query.interval_count"
          :label="`No. of records (in ${form.query.interval_unit})`"
          :items="countOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-select
          v-model="form.query.interval_duration"
          :label="`Interval Duration = Each record has length of (N) ${form.query.interval_unit}s`"
          :items="durationOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <div class="demo-space-x">
          Do you want to specify the start date of the first record?
          <v-switch
            v-model="specify"
            color="primary"
            :label="specify ? 'Yes, use a custom Start Date' : 'No, just use the latest date'"
          ></v-switch>
        </div>

        <v-date-picker
          v-if="specify"
          v-model="form.query.start_date"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Update
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { useCurrency } from '@/composables'
import productStore from '@/modules/product/store'
import store from '@/store'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'

export default {

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const { resource } = props
    const specify = ref(!!resource.query.start_date)

    const initialForm = {
      nicename: resource.nicename,
      sort: resource.sort,
      cols: resource.cols,
      formatter: resource.formatter,
      route: resource.route,
      filter: resource.filter,
      query: resource.query,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const formatterOptions = [
      { title: 'Default (count No.)', value: 'default' },
      { title: useCurrency().baseCurrency.value.long_symbol, value: 'dollar' },
    ]

    const countOptions = [
      { title: '3', value: 3 },
      { title: '4', value: 4 },
      { title: '5', value: 5 },
      { title: '6', value: 6 },
      { title: '7 (suggested)', value: 7 },
      { title: '8', value: 8 },
      { title: '9', value: 9 },
      { title: '10', value: 10 },
      { title: '12', value: 12 },
      { title: '14', value: 14 },

      // { title: '16', value: 16 },
      // { title: '18', value: 18 },
      // { title: '20', value: 20 },
      // { title: '24', value: 24 },
      // { title: '30', value: 30 },
    ]

    const unitOptions = [
      { title: 'Day', value: 'day' },
      { title: 'Week', value: 'week' },
      { title: 'Month', value: 'month' },
      { title: 'Year', value: 'year' },
    ]

    const durationOptions = [
      { title: '1 (suggested)', value: 1 },
      { title: '2', value: 2 },
      { title: '3', value: 3 },
      { title: '4', value: 4 },
      { title: '5', value: 5 },
      { title: '6', value: 6 },
      { title: '7', value: 7 },
      { title: '8', value: 8 },
      { title: '9', value: 9 },
      { title: '10', value: 10 },
    ]

    const colsOptions = [
      { title: '3', value: 3 },
      { title: '4 (1/3)', value: 4 },
      { title: '5', value: 5 },
      { title: '6 (half)', value: 6 },
      { title: '7', value: 7 },
      { title: '8', value: 8 },
      { title: '9', value: 9 },
      { title: '10', value: 10 },
      { title: '11', value: 11 },
      { title: '12 (full width)', value: 12 },
    ]

    const routeOptions = [
      { title: 'Order Items', value: 'order-items' },
      { title: 'Orders', value: 'orders' },
    ]

    const actionOptions = [
      { title: 'Count No.', value: 'count' },
      { title: 'Sum values (e.g. total)', value: 'sum' },
    ]

    const actionValueOptions = [
      { title: 'Total', value: 'total' },
      { title: 'Refund Total', value: 'refund_total' },
    ]

    const { natureOptions } = productStore.state
    const merchantOptions = store.getters['merchant/merchantOptions']
    const channelOptions = store.getters['channel/channelOptions']

    const validate = () => {
      const { query } = form.value
      if (query.action === 'count') {
        query.action_value = null
      }
      if (query.action === 'sum' && !query.action_value) {
        alert('action value is required')

        return
      }
      if (formElem.value.validate()) emit('submit', form.value)
    }

    watch(specify, bool => {
      if (bool) {
        form.value.query.start_date = moment().subtract(resource.query.interval_count, resource.query.interval_unit).startOf('day').format('YYYY-MM-DD')
      } else {
        form.value.query.start_date = null
      }
    })

    return {
      specify,
      form,
      formElem,

      formatterOptions,
      countOptions,
      unitOptions,
      durationOptions,

      colsOptions,
      routeOptions,
      actionOptions,
      actionValueOptions,

      natureOptions,
      merchantOptions,
      channelOptions,

      validate,
      required,
    }
  },
}
</script>
